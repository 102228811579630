// 病例管理api
import {get, put, post, del, getCase} from "@/service/base";
import qs from "qs";
//病例首页
export function getCaseData(title){
    return get('/v3/cases', {title},{headers:{'Content-Type':'multipart/form-data'}})
}

export function getCaseTitle(){
    return get('/v3/cases')
}
// 获取病例列表,病例搜索
export function getCaseSearch(data){
    return get('/api/admin/cases',data)
}
// 病例模板
export function getCaseTemplatesP(data){
    return get('/api/admin/caseTemplates',data)
}
export function getCaseTemplates(data){
    return get('/api/admin/caseTemplates',data)
}
// 病例打印列表
export function getCasesTable(data){
    return get('/api/admin/cases',data)
}
// 病例结算
export function getCasesAuditTable(data){
    return get('/api/admin/cases',data)
}
// 修改病例状态
export function putCaseCondition(id,data){
    return put('/api/admin/case/' +id,data)
}
// 下载病例
export function downCase(id){
    return get('/api/admin/exportCaseTemplate/' +id)
}
//批量改变打印状态
export function  putCasePrint(data){
    return put('/api/admin/case/batchPrint',qs.stringify(data))
}
//批量改变结算状态
export function putAccount(data){
    return put('/api/admin/case/batchAudit',qs.stringify(data))
}
//打印统计数据
export function getPrintCount(template_id,start_time,end_time){
    return get('/api/admin/case/statisticalTotalCount',{"type":'isPrint',template_id,start_time,end_time})
}
//结算统计数据
export function getAcCount(template_id,start_time,end_time){
    return get('/api/admin/case/statisticalTotalCount',{"type":'isAudit',template_id,start_time,end_time})
}
//导出病例信息表
export function getExport(templateId,start_time,emd_time){
    return  get('/api/admin/case/caseReportExcelAuditByCase/' +templateId,{start_time,emd_time})
}


//-------------------------病例项目管理------------------------------------

//获取病例项目列表
export function getCaseItems(pageNum,title,isopen){
    return get('/v3/casess',{pageNum,title,isopen})
}
export function getCaseId(id){
    return get('/v3/case/' + id)
}
//添加
export function postCaseItems(data){
    return post('/v3/case',data)
}
//查询人员
export function getCaseTel(tel){
    return get('/v3/case/tel',{tel})
}
//获取已选择的人员
export function getPeopleId(id,pageNum){
    return get('/v3/case/mem/' +id , {pageNum})
}
//添加人员
export  function  postPeople(data){
    return post('/v3/case/json',qs.stringify(data))
}

//删除已选择人员
export function delPeople(id,quance){
    return del('/v3/case/J/' +id , {quance})
}
//设置驳回原因
export function getReason(id){
    return get('/v3/case/remark/' +id ,)
}
//新增驳回原因
export function putReason(id,data){
    return put('/v3/case/remark/' +id ,qs.stringify(data))
}
//删除驳回原因
export function delReason(id,index){
    return del('/v3/case/remark/' +id +'/' + index)
}
//修改驳回原因
export function putReasonId(id,index,data){
    return put('/v3/case/remark/' +id +'/' + index,qs.stringify(data))
}
//设置医院
//新增医院
export function putHospital(id,data){
    return put('/v3/case/Hospital/' +id ,qs.stringify(data))
}
//获取已设置的医院列表
export function getHospital(id){
    return get('/v3/case/Hospital/' +id )
}
//删除已设置的医院
export function delHospital(id,index){
    return del('/v3/case/Hospital/' +id +'/' + index)
}
//病例模板开关
export  function putCaseSwitch(id,data){
    return put('/v3/case/'+id, qs.stringify(data))
}
//修改
export function putCaseItem(id,data){
    return put('/v3/case/all/' + id,data)
}
//删除
export function delCaseItem(id){
    return del('/v3/case/' + id)
}

//目标医院审核
export function getCaseHospital(){
    return get('/v3/hospital/authentication/templates')
}

export function getCaseHospitalInfo(id,pageNum,status,name){
    return get('/v3/hospital/authentication/roles/' + id,{pageNum,status,name})
}


/** 比伐芦定在冠心病患者PCI治疗中的应用真实世界研究临床观察表 **/
//获取病例问卷回显数据
export function getCaseCHD(id,memberId){
    return getCase("/case/wenjuan/member/zc",{id,memberId})
}

/** 获取医生用户信息 **/

export function memberInfo(memberId){
    return getCase("/case/wenjuan/member",{memberId})
}


//病例签字查看

export function getCaseSigns(data){
    return getCase("/case/wenjuan/sign",data)
}
