<template>
  <!--比伐芦定在冠心病患者PCI治疗中的应用真实世界研究临床观察表-->
  <div class="CHD_case" >
    <div id="printAre">
      <h2 style="text-align: center">比伐芦定在冠心病患者PCI治疗中的应用真实世界研究临床观察表</h2>
      <div class="table_case">
        <h3>医生基本信息</h3>
        <table border="1" width="100%">
          <tbody>
          <tr>
            <td class="table_header" width="25%">医生姓名:</td>
            <td width="30%">{{ doctor_info.name }}</td>
            <td class="table_header" width="20%">手机号:</td>
            <td width="25%">{{ doctor_info.tel }}</td>
          </tr>
          <tr>
            <td class="table_header">科室名称:</td>
            <td>{{ doctor_info.hospitalOffices }}</td>
            <td class="table_header">医生职称:</td>
            <td>{{ doctor_info.jobTitle }}</td>
          </tr>
          <tr>
            <td class="table_header">医院名称:</td>
            <td colspan='3'>{{ doctor_info.hospitalName }}</td>
          </tr>
          <tr>
            <td class="table_header">执业医师证号:</td>
            <td colspan='3'>{{ doctor_info.doctorNum }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="table_case">
        <h3>一、入选/排除标准</h3>
        <table border="1" width="100%">
          <tbody>
          <tr>
            <td class="table_header" width="75%">是否PCI围术期使用比伐芦定进行抗凝治疗的冠心病患者</td>
            <td width="25%">{{ case_form.caseReport.PCI }}</td>
          </tr>
          <tr>
            <td class="table_header" >是否签署书面知情同意书</td>
            <td >{{ case_form.caseReport.SigForm }}</td>
          </tr>
          <tr>
            <td class="table_header" >是否对比伐芦定及其辅料或水蛭素过敏者</td>
            <td >{{ case_form.caseReport.hirudin }}</td>
          </tr>
          <tr>
            <td class="table_header" >是否活动性出血者或存在出血性疾病导致出血风险增加，以及不可逆凝血功能障碍患者</td>
            <td >{{ case_form.caseReport.activeBleeding }}</td>
          </tr>
          <tr>
            <td class="table_header" >是否严重的未被控制的高血压</td>
            <td >{{ case_form.caseReport.hypertension }}</td>
          </tr>
          <tr>
            <td class="table_header" >是否亚急性细菌性心内膜炎</td>
            <td >{{ case_form.caseReport.subacute }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="table_case">
        <h3>二、人口学资料</h3>
        <table border="1" width="100%">
          <tbody>
          <tr>
            <td class="table_header" width="25%">患者昵称:</td>
            <td width="30%">{{ case_form.demographic.fullName }}</td>
            <td class="table_header" width="20%">性别:</td>
            <td width="25%">{{ case_form.demographic.sex }}</td>
          </tr>
          <tr>
            <td class="table_header">出生日期:</td>
            <td>{{ case_form.demographic.birth }}</td>
            <td class="table_header2">年龄(周岁):</td>
            <td>{{case_form.demographic.birth!==''? GetAge(case_form.demographic.birth) :''}}</td>
          </tr>
          <tr>
            <td class="table_header">住院ID编号:</td>
            <td>{{ case_form.demographic.patientNo }}</td>
            <td class="table_header2">民族:</td>
            <td>{{ case_form.demographic.nation }}</td>
          </tr>
          <tr>
            <td class="table_header">体重(kg):</td>
            <td>{{ case_form.demographic.weight }}</td>
            <td class="table_header2">身高(cm):</td>
            <td>{{ case_form.demographic.height}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="table_case">
        <h3>三、现病史及伴随疾病</h3>
        <table border="1" width="100%">
          <tbody>
          <tr>
            <td class="table_header" width="25%">现病史及伴随疾病:</td>
            <td colspan="5">{{ case_form.current.curent.toString() }}</td>
          </tr>
<!--          <tr>-->
<!--            <td class="table_header2">糖尿病类型:</td>-->
<!--            <td width="15%">{{ case_form.current.diabetesType }}</td>-->
<!--            <td width="15%" class="table_header2">糖尿病治疗方式:</td>-->
<!--            <td width="35%" colspan="3">{{case_form.current.diabetesTreatment.toString()}}</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td class="table_header2">卒中类型:</td>-->
<!--            <td colspan="5">{{ case_form.current.stroke.strokeType }}</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td class="table_header2">外科治疗史手术名称:</td>-->
<!--            <td  colspan="3">{{ case_form.current.wkzls.historySurgicalName }}</td>-->
<!--            <td  class="table_header2">手术日期:</td>-->
<!--            <td >{{ case_form.current.wkzls.historySurgicalDate }}</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td class="table_header2">肝病史:</td>-->
<!--            <td colspan="5">{{ case_form.current.gbs.liverDamage }}</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td  class="table_header2">慢性肾病史:</td>-->
<!--            <td colspan="5">{{ case_form.current.mxsbs.tmfcd }}</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td colspan="3" class="table_header2">计划进行冠状动脉、脑血管或外周动脉血运重建手术-手术名称:</td>-->
<!--            <td colspan="3">{{ case_form.current.planToSurgery.surgeryName }}</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td colspan="3" class="table_header2">计划进行心脏或非心脏大手术-手术名称:</td>-->
<!--            <td colspan="3">{{ case_form.current.majorSurgery.surgeryName }}</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td  class="table_header2">颅内血管异常:</td>-->
<!--            <td colspan="5">{{ case_form.current.bloodVessels.bloodVesselsType }}</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td class="table_header2">吸烟史-吸烟年限:</td>-->
<!--            <td width="15%">{{ case_form.current.smokes.smokeYear }}</td>-->
<!--            <td  class="table_header2">是否已戒烟:</td>-->
<!--            <td width="15%">{{ case_form.current.smokes.isSmoke }}</td>-->
<!--            <td width="15%" class="table_header2">已戒烟年限:</td>-->
<!--            <td width="15%">{{ case_form.current.smokes.smokeYearDie }}</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td  class="table_header2">饮酒史-是否已戒酒:</td>-->
<!--            <td colspan="5">{{ case_form.current.beers.isBeer }}</td>-->
<!--          </tr>-->
<!--          <tr>-->
<!--            <td  class="table_header2">严重疾病-预计生存期:</td>-->
<!--            <td colspan="5">{{ case_form.current.yzjb.severeDiseases }}</td>-->
<!--          </tr>-->
          </tbody>
        </table>
      </div>
      <div class="table_case">
        <h3>四、冠脉介入操作</h3>
        <table border="1" width="100%">
          <tbody>
          <tr>
            <td class="table_header" width="25%">手术日期:</td>
            <td colspan="3">{{ case_form.intervene.firstSurgeryDate }}</td>
          </tr>
          <tr>
            <td class="table_header2" >血管:</td>
            <td width="30%">{{ case_form.intervene.bloodVessel }}</td>
            <td class="table_header2" width="20%">其他:</td>
            <td >{{ case_form.intervene.bloodVessel_other }}</td>
          </tr>
          <tr>
            <td class="table_header2" >支架类型:</td>
            <td>{{ case_form.intervene.supportType }}</td>
            <td class="table_header2" >支架数量:</td>
            <td >{{ case_form.intervene.supportNum }}</td>
          </tr>
          <tr>
            <td class="table_header" >是否进行再次手术:</td>
            <td >{{ case_form.intervene.isSecondSurgery.isSecondSurgery }}</td>
            <td class="table_header2" >手术原因:</td>
            <td >{{ case_form.intervene.isSecondSurgery.secondSurgeryReason }}</td>
          </tr>
          <tr>
            <td class="table_header2" >第二次手术日期:</td>
            <td colspan="3">{{ case_form.intervene.isSecondSurgery.secondSurgeryDate }}</td>
          </tr>
          <tr>
            <td class="table_header2" >血管:</td>
            <td >{{ case_form.intervene.isSecondSurgery.secondSurgeryBloodVessel }}</td>
            <td class="table_header2" >其他:</td>
            <td >{{ case_form.intervene.isSecondSurgery.bloodVessel_other }}</td>
          </tr>
          <tr>
            <td class="table_header2" >支架类型:</td>
            <td >{{ case_form.intervene.isSecondSurgery.secondSurgerySupportType }}</td>
            <td class="table_header2" >支架数量:</td>
            <td >{{ case_form.intervene.isSecondSurgery.secondSurgerySupportNum }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="table_case" >
        <h3>五、生命体征</h3>
        <table border="1" width="100%">
          <tbody>
          <tr>
            <td class="table_header" width="25%">生命体征检查:</td>
            <td width="30%">{{ case_form.vitalSigns.isVital }}</td>
            <td class="table_header" width="20%">检查日期:</td>
            <td >{{ case_form.vitalSigns.checkDate }}</td>
          </tr>
          <tr>
            <td class="table_header2" >血压-收缩压(mmHg):</td>
            <td >{{ case_form.vitalSigns.xy.bloodPressure }}</td>
            <td class="table_header2" >血压-舒张压(mmHg):</td>
            <td >{{ case_form.vitalSigns.xy.bloodPressureDiastolic }}</td>
          </tr>
          <tr>
            <td class="table_header2" >检查结果:</td>
            <td >{{ case_form.vitalSigns.results.checkResults }}</td>
            <td class="table_header2" >异常描述:</td>
            <td >{{ case_form.vitalSigns.results.exception }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="table_case">
        <h3>六、导联心电图（ECG）</h3>
        <table border="1" width="100%">
          <tbody>
          <tr>
            <td class="table_header" width="25%">导联心电图检查:</td>
            <td width="30%">{{ case_form.leadEcg.isEcg }}</td>
            <td class="table_header2" width="20%">检查日期:</td>
            <td >{{ case_form.leadEcg.checkDate }}</td>
          </tr>
          <tr>
            <td class="table_header2" >心率:</td>
            <td colspan="3" >{{ case_form.leadEcg.xy }}</td>
          </tr>
          <tr>
            <td class="table_header2" >检查结果:</td>
            <td >{{ case_form.leadEcg.result.checkResults }}</td>
            <td class="table_header2" >异常描述:</td>
            <td >{{ case_form.leadEcg.result.exception }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="table_case" style="margin-top: 20px">
        <h3>七、超声心动图（UCG）</h3>
        <table border="1" width="100%">
          <tbody>
          <tr>
            <td class="table_header" width="25%">超声心动图检查:</td>
            <td width="30%">{{ case_form.echo.isEcho }}</td>
            <td class="table_header2" width="20%">检查日期:</td>
            <td >{{ case_form.echo.checkDate }}</td>
          </tr>
<!--          <tr>-->
<!--            <td colspan="2" class="table_header2" >左室舒张末期内径（LVEDD）(mm):</td>-->
<!--            <td colspan="2" >{{ case_form.echo.leftLVEDD }}</td>-->
<!--          </tr>-->
          <tr>
            <td colspan="2" class="table_header2" >射血分数(EF)(%):</td>
            <td colspan="2">{{ case_form.echo.bloodEF }}</td>
            <!--          <td class="table_header2" >E峰与A峰比值E/A:</td>-->
            <!--          <td >{{ case_form.echo.ECompareA }}</td>-->
          </tr>
          </tbody>
        </table>
      </div>
      <div class="table_case">
        <h3>八、血常规</h3>
        <table border="1" width="100%">
          <tbody>
          <tr>
            <td class="table_header" width="25%">血常规检查:</td>
            <td width="30%">{{ case_form.bloodRoutine.isBloodRoutine }}</td>
            <td class="table_header2" width="30%">检查日期:</td>
            <td >{{ case_form.bloodRoutine.checkDate }}</td>
          </tr>
          <tr>
            <td class="table_header2" >白细胞总数(WBC)(×10⁹/L):</td>
            <td >{{ case_form.bloodRoutine.WBCNum.result }}</td>
            <td class="table_header2" >中性粒细胞计数(NEUT)(×10⁹/L):</td>
            <td >{{ case_form.bloodRoutine.NEUTNum.result }}</td>
          </tr>
          <tr>
            <td class="table_header2" >血红蛋白(Hb)(g/L):</td>
            <td >{{case_form.bloodRoutine.HB.result }}</td>
            <td class="table_header2" >红细胞总数(RBC)(×10¹²/L):</td>
            <td >{{case_form.bloodRoutine.RBCNum.result }}</td>
          </tr>
          <tr>
            <td class="table_header2" >血小板（PLT）(×10⁹/L):</td>
            <td colspan="3">{{case_form.bloodRoutine.PLT.result }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="table_case">
        <h3>九、尿常规</h3>
        <table border="1" width="100%">
          <tbody>
          <tr>
            <td class="table_header" width="25%">尿常规检查:</td>
            <td width="30%">{{ case_form.urinalysis.isUrinalysis }}</td>
            <td class="table_header2" width="20%">检查日期:</td>
            <td >{{ case_form.urinalysis.checkDate }}</td>
          </tr>
          <tr>
            <td class="table_header2" >尿蛋白定性（{{case_form.urinalysis.urineProtein.unit}}）:</td>
            <td >{{ case_form.urinalysis.urineProtein.result }}</td>
            <td class="table_header2" >尿糖（{{case_form.urinalysis.urineSugar.unit}}）:</td>
            <td >{{ case_form.urinalysis.urineSugar.result }}</td>
          </tr>
          <tr>
            <td class="table_header2" >尿隐血（{{case_form.urinalysis.urineBlood.unit}}）:</td>
            <td colspan="3">{{case_form.urinalysis.urineBlood.result }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="table_case">
        <h3>十、血生化+糖化血红蛋白</h3>
        <table border="1" width="100%">
          <tbody>
          <tr>
            <td class="table_header" width="30%">血生化+糖化血红蛋白检查:</td>
            <td width="20%">{{ case_form.bloodGly.isBloodGly }}</td>
            <td class="table_header2" width="30%">检查日期:</td>
            <td >{{ case_form.bloodGly.checkDate }}</td>
          </tr>
          <tr>
            <td class="table_header2" >谷丙转氨酶（ALT/GPT）(U/L):</td>
            <td >{{ case_form.bloodGly.ALT.result }}</td>
            <td class="table_header2" >谷草转氨酶（AST/GOT）(U/L):</td>
            <td >{{ case_form.bloodGly.AST.result }}</td>
          </tr>
          <tr>
            <td class="table_header2" >总胆固醇（TC）(mmol/L):</td>
            <td >{{ case_form.bloodGly.TC.result }}</td>
            <td class="table_header2" >甘油三脂（TG）(mmol/L):</td>
            <td >{{ case_form.bloodGly.TG.result }}</td>
          </tr>
          <tr>
            <td class="table_header2" >低密度脂蛋白（LDL-C）(mmol/L):</td>
            <td >{{ case_form.bloodGly.LDL.result }}</td>
            <td class="table_header2" >高密度脂蛋白（HDL-C）(mmol/L):</td>
            <td >{{ case_form.bloodGly.HDL.result }}</td>
          </tr>
          <tr>
            <td class="table_header2" >空腹血糖(mmol/L):</td>
            <td >{{ case_form.bloodGly.bloodSugar.result }}</td>
            <td class="table_header2" >肌酐（Cr）{{'('+case_form.bloodGly.CR.unit+')'}}:</td>
            <td >{{ case_form.bloodGly.CR.result }}</td>
          </tr>
          <tr>
            <td class="table_header2" >血尿酸（UA）(μmol/L):</td>
            <td >{{ case_form.bloodGly.UA.result }}</td>
            <td class="table_header2" >钾（K+）(mmol/L):</td>
            <td >{{ case_form.bloodGly['K+'].result }}</td>
          </tr>
          <tr>
            <td class="table_header2" >糖化血红蛋白（HbA1c）(%):</td>
            <td colspan="3">{{ case_form.bloodGly.HBA1C.result }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="table_case">
        <h3>十一、入组抗栓药物用药情况</h3>
        <table border="1" width="100%">
          <tbody>
          <tr>
            <td class="table_header" width="25%">抗凝药物是否使用比伐芦定:</td>
            <td width="30%">{{ case_form.drugStatus.doesUse }}</td>
            <td class="table_header" width="20%">总剂量(mg):</td>
            <td >{{ case_form.drugStatus.doesUses }}</td>
          </tr>
          <tr>
            <td class="table_header2" >其他抗凝药物使用(IU):</td>
            <td colspan="3">{{ case_form.drugStatus.otherUse }}</td>
          </tr>
          <tr>
            <td class="table_header2" >血小板环氧化酶抑制药(mg):</td>
            <td colspan="3">{{ case_form.drugStatus.platelet }}</td>
          </tr>
          <tr>
            <td class="table_header2" >ADP受体拮抗剂(mg):</td>
            <td colspan="3">{{ case_form.drugStatus.ADP}}</td>
          </tr>
          <tr>
            <td class="table_header2" >磷酸二酯酶抑制药(mg):</td>
            <td colspan="3">{{ case_form.drugStatus.phospate}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="table_case">
        <h3>十二、ACT监测</h3>
        <table border="1" width="100%">
          <tbody>
          <tr>
            <td class="table_header" width="25%">ACT监测检查:</td>
            <td width="30%">{{ case_form.ACT.isACT }}</td>
            <td class="table_header2" width="20%">检查日期:</td>
            <td >{{ case_form.ACT.checkDate }}</td>
          </tr>
          <tr>
            <td class="table_header2">术中(s):</td>
            <td >{{ case_form.ACT.intra.result }}</td>
            <td class="table_header2" >术后2小时(s):</td>
            <td >{{ case_form.ACT.intraTwoHours.result }}</td>
          </tr>
          <tr>
            <td class="table_header2">术后6小时(s):</td>
            <td >{{ case_form.ACT.intraSixHours.result }}</td>
            <td class="table_header2" >术后8小时(s):</td>
            <td >{{ case_form.ACT.intraEightHours.result }}</td>
          </tr>
          <tr>
            <td class="table_header2">术后10小时(s):</td>
            <td >{{ case_form.ACT.intraTenHours.result }}</td>
            <td class="table_header2" >其他(s):</td>
            <td >{{ case_form.ACT.other.result }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="table_case">
        <h3>十三、随访信息</h3>
        <table border="1" width="100%">
          <tbody>
          <tr>
            <td class="table_header" width="25%">随访方式:</td>
            <td width="30%">{{ case_form.followUP.followMethod }}</td>
            <td class="table_header2" width="20%">随访日期:</td>
            <td >{{ case_form.followUP.followDate }}</td>
          </tr>
          <tr>
            <td class="table_header2">未进行随访的原因:</td>
            <td colspan="3">{{ case_form.followUP.remark }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="table_case">
        <h3>十四、临床事件</h3>
        <table border="1" width="100%">
          <tbody>
          <tr>
            <td class="table_header" width="30%">是否发生方案偏离:</td>
            <td >{{ case_form.clinicalEvents.isFapl.fapl }}</td>
          </tr>
          <tr>
            <td class="table_header">是否发生不良事件/严重不良事件:</td>
            <td >{{ case_form.clinicalEvents.isAdverse.adverse }}</td>
          </tr>
          <tr>
            <td class="table_header">是否发生终点相关事件:</td>
            <td >{{ case_form.clinicalEvents.isRelated.related }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="table_case">
        <h3>十五、方案偏离表</h3>
        <table border="1" width="100%">
          <tbody>
          <tr>
            <td class="table_header2" width="25%">偏离编号:</td>
            <td width="30%">{{ case_form.clinicalEvents.isFapl.faplTable.plNo }}</td>
            <td class="table_header2" width="20%">偏离日期:</td>
            <td >{{ case_form.clinicalEvents.isFapl.faplTable.plDate }}</td>
          </tr>
          <tr>
            <td class="table_header2" >偏离发生于:</td>
            <td >{{ case_form.clinicalEvents.isFapl.faplTable.plIN }}</td>
            <td class="table_header2" >偏离程度:</td>
            <td >{{ case_form.clinicalEvents.isFapl.faplTable.plcd }}</td>
          </tr>
          <tr>
            <td class="table_header2" >偏离描述:</td>
            <td colspan="3">{{ case_form.clinicalEvents.isFapl.faplTable.plms.toString() }}</td>
          </tr>
          <tr>
            <td class="table_header2" >出现偏离的原因:</td>
            <td colspan="3">{{ case_form.clinicalEvents.isFapl.faplTable.cxplyy.toString() }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="table_case">
        <h3>十六、不良事件表</h3>
        <table border="1" width="100%">
          <tbody>
          <tr>
            <td class="table_header2" width="25%">事件编号:</td>
            <td width="30%">{{ case_form.clinicalEvents.isAdverse.adverseTable.adverseNo }}</td>
            <td class="table_header2" width="20%">事件名称:</td>
            <td >{{case_form.clinicalEvents.isAdverse.adverseTable.adverseName}}</td>
          </tr>
          <tr>
            <td class="table_header2" >不良事件描述:</td>
            <td colspan="3">{{ case_form.clinicalEvents.isAdverse.adverseTable.adverseDescribe  }}</td>
          </tr>
          <tr>
            <td class="table_header2" >发生日期:</td>
            <td >{{  case_form.clinicalEvents.isAdverse.adverseTable.eventDate  }}</td>
            <td class="table_header2" >结束日期:</td>
            <td >{{ case_form.clinicalEvents.isAdverse.adverseTable.eventDieDate }}</td>
          </tr>
          <tr>
            <td class="table_header2" >不良事件分级:</td>
            <td >{{  case_form.clinicalEvents.isAdverse.adverseTable.eventGrade  }}</td>
            <td class="table_header2">与试验药物的关系:</td>
            <td >{{ case_form.clinicalEvents.isAdverse.adverseTable.withDrug }}</td>
          </tr>
          <tr>
            <td class="table_header2" >与手术/治疗的关系:</td>
            <td >{{  case_form.clinicalEvents.isAdverse.adverseTable.withSurgery  }}</td>
            <td class="table_header2" >对不良事件采取的措施:</td>
            <td >{{ case_form.clinicalEvents.isAdverse.adverseTable.event.eventTaken }}</td>
          </tr>
          <tr>
            <td class="table_header2" >不良事件转归:</td>
            <td >{{  case_form.clinicalEvents.isAdverse.adverseTable.outCome  }}</td>
            <td class="table_header2" >因不良事件而退出试验:</td>
            <td >{{ case_form.clinicalEvents.isAdverse.adverseTable.isExitInEvent }}</td>
          </tr>
          <tr>
            <td class="table_header2" >是否为严重不良事件:</td>
            <td colspan="3">{{ case_form.clinicalEvents.isAdverse.adverseTable.isSeriousEvent  }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="table_case">
        <h3>十七、严重不良事件表</h3>
        <table border="1" width="100%">
          <tbody>
          <tr>
            <td class="table_header2" width="25%">事件编号:</td>
            <td width="30%">{{ case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.yzEventNo }}</td>
            <td class="table_header2" width="20%">事件名称:</td>
            <td >{{case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAEName}}</td>
          </tr>
          <tr>
            <td class="table_header2" >发生日期:</td>
            <td >{{  case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.occurDate  }}</td>
            <td class="table_header2" >结束日期:</td>
            <td >{{ case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.getDate }}</td>
          </tr>
          <tr>
            <td class="table_header2" >报告日期:</td>
            <td >{{  case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.reportDate  }}</td>
            <td class="table_header2" >报告类型:</td>
            <td >{{ case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.reportType }}</td>
          </tr>
          <tr>
            <td class="table_header2" >SAE情况:</td>
            <td colspan="3">{{ case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAECondition  }}</td>
          </tr>
          <tr>
            <td class="table_header2" >SAE转归:</td>
            <td colspan="3">{{ case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAEOutCome  }}</td>
          </tr>
          <tr>
            <td class="table_header2" >与试验药物的关系:</td>
            <td >{{  case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.withDrug  }}</td>
            <td class="table_header2">与手术/治疗的关系:</td>
            <td >{{ case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.withSurgery }}</td>
          </tr>
          <tr>
            <td class="table_header2" >SAE报告情况国内:</td>
            <td >{{  case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAEReportingDomestic  }}</td>
            <td class="table_header2" >SAE报告情况国外:</td>
            <td >{{ case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAEReportingOverseas }}</td>
          </tr>
          <tr>
            <td class="table_header2" >SAE发生及处理情况详细描述:</td>
            <td colspan="3">{{ case_form.clinicalEvents.isAdverse.adverseTable.yzEvent.SAEDescribe  }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="table_case">
        <h3>十八、终点事件表</h3>
        <table border="1" width="100%">
          <tbody>
          <tr>
            <td class="table_header2" width="25%">是否发生死亡:</td>
            <td width="10%">{{ case_form.clinicalEvents.isRelated.relatedTable.isDie.die }}</td>
            <td class="table_header2" width="12%">死亡类型:</td>
            <td width="18%">{{case_form.clinicalEvents.isRelated.relatedTable.isDie.dieMessage.dieType}}</td>
            <td class="table_header2" width="15%">死亡日期:</td>
            <td >{{case_form.clinicalEvents.isRelated.relatedTable.isDie.dieMessage.dieDate}}</td>
          </tr>
          <tr>
            <td class="table_header2" colspan="2">死亡前是否因病入院接收治疗:</td>
            <td colspan="2">{{case_form.clinicalEvents.isRelated.relatedTable.isDie.dieMessage.hospitalToBeforeDie}}</td>
            <td  class="table_header2" >入院日期:</td>
            <td colspan="2">{{case_form.clinicalEvents.isRelated.relatedTable.isDie.dieMessage.inHospitalDate}}</td>
          </tr>
          <tr>
            <td class="table_header2" colspan="2">是否发生血运重建:</td>
            <td colspan="2">{{case_form.clinicalEvents.isRelated.relatedTable.isOccurred}}</td>
            <td  class="table_header2" >重建术类型:</td>
            <td colspan="2">{{case_form.clinicalEvents.isRelated.relatedTable.occurredType}}</td>
          </tr>
          <tr>
            <td class="table_header2" colspan="2">是否为紧急血运重建:</td>
            <td colspan="2">{{case_form.clinicalEvents.isRelated.relatedTable.isUrgentOccurred}}</td>
            <td  class="table_header2" >发生日期:</td>
            <td colspan="2">{{case_form.clinicalEvents.isRelated.relatedTable.occurredDate}}</td>
          </tr>
          <tr>
            <td class="table_header2" colspan="2">是否因紧急血运重建入院进行治疗:</td>
            <td colspan="2">{{case_form.clinicalEvents.isRelated.relatedTable.isUrgentOccurredHospital}}</td>
            <td  class="table_header2" >入院日期:</td>
            <td colspan="2">{{case_form.clinicalEvents.isRelated.relatedTable.inHospitalDateUrgent}}</td>
          </tr>
          <tr>
            <td class="table_header2" >是否发生脑卒中:</td>
            <td >{{ case_form.clinicalEvents.isRelated.relatedTable.isStroke.stroke }}</td>
            <td class="table_header2" >卒中类型:</td>
            <td >{{case_form.clinicalEvents.isRelated.relatedTable.isStroke.strokeType}}</td>
            <td class="table_header2" >发生日期:</td>
            <td >{{case_form.clinicalEvents.isRelated.relatedTable.isStroke.strokeDate}}</td>
          </tr>
          <tr>
            <td class="table_header2" colspan="2">是否因脑卒中入院进行治疗:</td>
            <td colspan="2">{{case_form.clinicalEvents.isRelated.relatedTable.isStrokeHospital}}</td>
            <td  class="table_header2" >入院日期:</td>
            <td colspan="2">{{case_form.clinicalEvents.isRelated.relatedTable.inHospitalDateStroke}}</td>
          </tr>
          <tr>
            <td class="table_header2" colspan="2">是否发生不稳定心绞痛:</td>
            <td colspan="2">{{case_form.clinicalEvents.isRelated.relatedTable.isAngina}}</td>
            <td  class="table_header2" >发生日期:</td>
            <td colspan="2">{{case_form.clinicalEvents.isRelated.relatedTable.inHospitalDateAngina}}</td>
          </tr>
          <tr>
            <td class="table_header2" colspan="2">是否因不稳定心绞痛入院进行治疗:</td>
            <td colspan="2">{{case_form.clinicalEvents.isRelated.relatedTable.isAnginaHospital}}</td>
            <td  class="table_header2" >入院日期:</td>
            <td colspan="2">{{case_form.clinicalEvents.isRelated.inHospitalDateAngina}}</td>
          </tr>
          <tr>
            <td class="table_header2" colspan="2">是否发生心肌梗死:</td>
            <td colspan="2">{{case_form.clinicalEvents.isRelated.relatedTable.isInfarction}}</td>
            <td  class="table_header2" >发生日期:</td>
            <td colspan="2">{{case_form.clinicalEvents.isRelated.relatedTable.infarctionDate}}</td>
          </tr>
          <tr>
            <td class="table_header2" colspan="2">是否因心肌梗死入院进行治疗:</td>
            <td colspan="2">{{case_form.clinicalEvents.isRelated.relatedTable.isInfarctionHospital}}</td>
            <td  class="table_header2" >入院日期:</td>
            <td colspan="2">{{case_form.clinicalEvents.isRelated.relatedTable.inHospitalDateInfarction}}</td>
          </tr>
          <tr>
            <td class="table_header2" colspan="2">是否短暂性脑缺血发作:</td>
            <td colspan="2">{{case_form.clinicalEvents.isRelated.relatedTable.isAttack}}</td>
            <td  class="table_header2" >发生日期:</td>
            <td colspan="2">{{case_form.clinicalEvents.isRelated.relatedTable.attackDate}}</td>
          </tr>
          <tr>
            <td class="table_header2" colspan="2">是否因短暂性脑缺血发作入院进行治疗:</td>
            <td colspan="2">{{case_form.clinicalEvents.isRelated.relatedTable.isAttackHospital}}</td>
            <td  class="table_header2" >入院日期:</td>
            <td colspan="2">{{case_form.clinicalEvents.isRelated.relatedTable.inHospitalDateAttack}}</td>
          </tr>
          <tr>
            <td class="table_header2" >是否发生支架内血栓:</td>
            <td >{{ case_form.clinicalEvents.isRelated.relatedTable.isStent }}</td>
            <td class="table_header2" >血栓分类:</td>
            <td >{{case_form.clinicalEvents.isRelated.relatedTable.stentType}}</td>
            <td class="table_header2" >发生日期:</td>
            <td >{{case_form.clinicalEvents.isRelated.relatedTable.stentDate}}</td>
          </tr>
          <tr>
            <td class="table_header2" >是否发生出血:</td>
            <td >{{ case_form.clinicalEvents.isRelated.relatedTable.isBlood }}</td>
            <td class="table_header2" >BARC类型:</td>
            <td >{{case_form.clinicalEvents.isRelated.relatedTable.BARCBlood}}</td>
            <td class="table_header2" >发生日期:</td>
            <td >{{case_form.clinicalEvents.isRelated.relatedTable.bloodDate}}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div >


      </div>
      <div style="margin-top: 80px">
        <h1 style="text-align: center;">主要研究者声明</h1>
        <h1 style="margin-bottom: 10%">我声明对此份病例报告表已经做过审核，病例报告表记录的所有内容完整，真实，且遵从研究方案的要求。</h1>
        <h1>主要研究者签名：______________</h1>
        <h1>签名日期：______________</h1>
      </div>
    </div>
    <div style="display: flex;justify-content: center">
      <a-button type="primary"  v-print="printAre">打印</a-button>
    </div>

  </div>
</template>

<script>
import {getCaseCHD, memberInfo} from "@/service/CaseTemplate_api";

export default {
  name: "CHD_case",
  data() {
    return {
      printAre: {
        id: "printAre",
      },
      doctor_info: {
        name: "",//姓名/开户名
        hospitalName: "",//医院
        hospitalOffices: "",//科室
        jobTitle: "",//职称
        tel: "",//手机号
        idCard: null,//身份证号
        bankName: null,//开户行
        bankCard: null,//银行卡号
        doctorNum: ""//执业医生证号
      },
      case_form:{
        drugLog: '',
        //病例报告确认
        caseReport:{
          PCI: '', //PCI围术期使用比伐芦定进行抗凝治疗的冠心病患者
          SigForm:'', //已签署书面知情同意书
          hirudin: '', //对比伐芦定及其辅料或水蛭素过敏者
          activeBleeding:'', //活动性出血者或存在出血性疾病导致出血风险增加，以及不可逆凝血功能障碍患者
          hypertension: '', //严重的未被控制的高血压
          subacute: '', //亚急性细菌性心内膜炎
          show:true,
        },
        //人口学资料
        demographic: {
          fullName:'',//姓名缩写（拼音首字母大写）
          birth:'', //出生日期
          patientNo: '',
          sex: '', //性别
          nation: '', //民族
          height:'', //身高cm
          weight: '', //体重Kg
          show:false,
        },
        //现病史及伴随疾病
        current: {
          show:false,
          curent:[],
          diabetesType:'', //糖尿病类型
          diabetesType_other:'',
          diabetesTreatment:[],  //糖尿病治疗方式（有）
          stroke:{
            str:'',
            strokeType:'' //卒中类型（有）
          },
          wkzls:{ //外科治疗史
            wk:'',
            historySurgicalName: '', //外科治疗史——手术名称
            historySurgicalDate: '' //外科治疗史——手术日期
          },

          xhxkys: '', //消化性溃疡史
          gbs: { //肝病史
            gb:'', // 肝病史
            liverDamage: '' //肝功能损伤
          },
          mxsbs: {
            mxsb:'', //慢性肾病史
            tmfcd: '' //慢性肾病采取的治疗措施
          },
          isAsplOrADP: '', //是否有阿司匹林或ADP受体阻滞剂应用禁忌症
          planToSurgery: {
            planToSurgery: '', ////计划进行冠状动脉、脑血管或外周动脉血运重建手术
            surgeryName: '' //手术名称
          },
          majorSurgery: {
            majorSurgery:'', //计划进行心脏或非心脏大手术
            surgeryName: '' //手术名称
          },
          useCYP2C19: '', //计划联合使用CYP2C19强效或中效抑制剂
          vesselsBlood: '', // 颅内出血
          zssjxtzl: '', //中枢神经系统肿瘤
          bloodVessels: {
            bloodVessel:'', // 颅内血管异常
            bloodVesselsType: '' //颅内血管异常形式
          },
          smokes: {
            smoke: '', //吸烟史
            smokeYear: '', //吸烟年限
            isSmoke: '', //是否已戒烟
            smokeYearDie: '' //戒烟年限
          },
          beers: {
            beer: '', //饮酒史
            isBeer:'' //是否已戒酒
          },
          hyOrbr:'', //计划怀孕或者正在哺乳
          jsbs: '', //精神病史
          yzjb: {
            yzjb: '', //严重疾病
            severeDiseases: '' //预计生存期
          },
          inClinical: '' //  正在参与其它器械或药物临床试验，且未达到主要终点
        },
        intervene: { //冠脉介入操作
          show:false,
          bloodVessel_other:'',
          firstSurgeryDate: '', //第一次手术日期
          bloodVessel: '', //血管
          supportType: '', //支架类型
          supportNum: '', //支架数量
          isSecondSurgery: { //是否进行第二次手术
            bloodVessel_other:'',
            isSecondSurgery: '', // 是否进行第二次手术（如果是展示下面）
            secondSurgeryReason: '', //二次手术原因
            secondSurgeryDate: '', //第二次手术日期
            secondSurgeryBloodVessel: '', //第二次手术血管
            secondSurgerySupportType:'', //第二次手术支架类型
            secondSurgerySupportNum: '' //第二次手术支架数量
          }
        },
        vitalSigns: { //生命体征
          show:false,
          isVital: '', //是否检查生命体征
          checkDate: '', //检查日期
          xy: {
            bloodPressure: '', //mmHg血压-收缩压
            bloodPressureDiastolic: '' //mmHg血压-舒张压
          },
          results: {
            checkResults: '', //检查结果（异常则填写异常描述）
            exception: '' //异常描述
          }
        },
        leadEcg: { //导联心电图（ECG）
          show:false,
          isEcg: '', //是否检查导联心电图（ECG）
          checkDate:'', //检查日期
          xl: '', //心率次/分
          result: {
            checkResults: '', //检查结果（如果为异常则填写异常描述）
            exception: '' //异常描述
          }
        },
        echo: { //超声心动图
          show:false,
          isEcho: '', //是否检查超声心动图
          checkDate: '', //检查日期
          leftLVEDD: '', //左室舒张末期内径（LVEDD）（单位：mm）
          bloodEF: '', //射血分数(EF)（单位：%）
          ECompareA:''//E峰与A峰比值E/A
        },
        bloodRoutine: { //血常规
          show:false,
          isBloodRoutine: '', //是否检查血常规
          checkDate: '', //检查日期
          WBCNum: { //白细胞总数（WBC)
            result:'', //结果
            unit: "×10(9次方)/L", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          NEUTNum: { //中性粒细胞计数（NEUT）
            result: '', //结果
            unit: "×10(9次方)/L", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          HB: { //血红蛋白（Hb)
            result: '', //结果
            unit: "g/L", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          RBCNum: { //红细胞总数（RBC）
            result: '', //结果
            unit: "×10(12次方)/L", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          PLT: { //血小板（PLT）
            result:'', //结果
            unit: "×10(9次方)/L", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          }
        },
        urinalysis: { //尿常规
          show:false,
          isUrinalysis: '', //是否检查尿常规
          checkDate: '', //检查日期
          urineProtein: { //尿蛋白定性
            result: '', //结果
            unit: '', //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          urineSugar: { //尿糖
            result: '', //结果
            unit: '', //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          urineBlood: { //尿隐血
            result: '', //结果
            unit: '', //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          }
        },
        bloodGly: { //血生化+糖化血红蛋白
          show:false,
          isBloodGly: '', //否检查血生化+糖化血红蛋白
          checkDate: '', //检查日期
          ALT: { //谷丙转氨酶（ALT/GPT）（单位：IU/L）
            result: '', //结果
            unit: "IU/L", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          AST: { //谷草转氨酶（AST/GOT）（单位：IU/L）
            result: '', //结果
            unit: "IU/L", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          TC: { //总胆固醇（TC）（mmol/L）
            result: '', //结果
            unit: "mmol/L", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          TG: { //甘油三脂（TG）（单位：mmol/L）
            result: '', //结果
            unit: "mmol/L", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          LDL: { //低密度脂蛋白（LDL-C）（单位：mmol/L）
            result: '', //结果
            unit: "mmol/L", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          HDL: { //高密度脂蛋白（HDL-C）（单位：mmol/L）
            result: '', //结果
            "unit": "mmol/L", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          bloodSugar: { //空腹血糖（单位：mmol/L）
            result: '', //结果
            "unit": "mmol/L", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          CR: { //肌酐（Cr）
            result: '', //结果
            unit: 'μmol/L', //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          UA: { //血尿酸（UA）（单位：μmol/L）
            result: '', //结果
            "unit": "μmol/L", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          'K+': { //钾（K+）（单位：mmol/L）
            result: '', //结果
            "unit": "mmol/L", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          HBA1C: { //糖化血红蛋白（HbA1c）（单位：%）
            result: '', //结果
            "unit": "%", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          }
        },
        drugStatus: { //入组抗栓药物用药情况
          show:false,
          doesUse: '', //抗凝药物是否使用比伐芦定 “是”加每日剂量
          otherUse:'', //其他抗凝药物使用
          platelet:'', //血小板环氧化酶抑制药  "aa 4mg"
          ADP:'', //ADP受体拮抗剂 多项拼接  数组转字符串 "aa 4mg"
          phospate:''//磷酸二酯酶抑制药 多项拼接  数组转字符串 "aa 4mg"
        },
        ACT: { //ACT监测
          show:false,
          isACT: '', //是否检查ACT
          checkDate: '', //检查日期
          intra: { //术中（单位：S）
            result: '', //结果
            "unit": "s", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          intraTwoHours: { //术后2小时（单位：S）
            result: '', //结果
            "unit": "s", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          intraSixHours: { //术后6小时（单位：S）
            result: '', //结果
            "unit": "s", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          intraEightHours: { //术后8小时（单位：S）
            result: '', //结果
            unit: "s", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          intraTenHours: { //术后10小时（单位：S）
            result: '', //结果
            "unit": "s", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          },
          other: { //其他（单位：S）
            result: '', //结果
            "unit": "s", //单位
            "otherUnit": "", //其他单位
            "zcz": { //正常值范围
              "xx": "", //下限
              "sx": "" //上限
            }
          }
        },

        followUP: { //随访信息
          show:false,
          followDate: '', //随访日期
          followMethod: '', //随访方式(未进行随访则填写原因)
          remark: '' //未进行随访原因

        },
        clinicalEvents: { //临床事件
          isFapl: { //是否发生方案偏离
            fapl: '', //(是：填写方案偏离表)
            faplTable: { //方案偏离表
              plNo: '', //偏离编号
              plDate: '', //偏离日期
              plIN: '',   //偏离发生于
              plcd:'',//偏离程度
              plms: [],//偏离描述）
              cxplyy: [] //出现偏离原因
            }
          },
          show:false,

          isAdverse: { //是否发生不良事件/严重不良事件
            adverse: '', //(是：填写不良事件表)
            adverseTable: { //不良事件表
              adverseNo: '', //不良事件编号
              adverseName: '', //不良事件名称
              adverseDescribe: '', //不良事件描述
              eventDate: '', //事件发生日期
              eventDieDate: '', //时间结束日期
              eventGrade:'', //不良事件分级
              withDrug:'',//与试验药物的关系
              withSurgery:'', //与手术/治疗的关系
              event: {
                eventTaken: '', //对不良事件采取的措施（有：填写不良事件采取的措施的记录）
                eventLog: '' //对不良事件采取的措施的记录
              },
              outCome: '', //不良事件转归
              isExitInEvent: '', //因不良事件而退出试验
              isSeriousEvent: '', //是否为严重不良事件（是：填写严重不良事件表）
              yzEvent: { //严重不良事件表
                yzEventNo: '', //严重不良事件编号
                SAEName: '', //SAE名称
                occurDate: '', //发生日期
                getDate: '', //获知日期
                reportDate: '', //报告日期
                reportType: '', //报告类型
                SAECondition:'',//SAE情况
                SAEOutCome: '',//SAE转归
                withDrug: '', //与试验药物的关系
                withSurgery:'',//与手术/治疗的关系
                SAEReportingDomestic: '',//SAE报道情况（国内）
                SAEReportingOverseas: '', //SAE报道情况（国外）
                SAEDescribe: '' //SAE发生及处理情况详细描述
              }
            }
          },
          isRelated: {
            related: '', //是否发生终点事件（是：填写终点事件表）
            relatedTable: { //终点事件表
              isDie: { //是否发生死亡
                die: '', //（是则填写死亡信息）
                dieMessage: { //死亡信息
                  dieType: '', //死亡类型
                  dieDate: '', //死亡日期
                  hospitalToBeforeDie:'', //死亡前是否因病入院接收治疗（是：填写入院日期）
                  inHospitalDate: '' //入院日期
                }
              },
              isOccurred: '', //是否发生血运重建
              occurredType:'', //重建术类型
              isUrgentOccurred: '', //是否为紧急血运重建
              occurredDate: '', //血运重建发生日期
              isUrgentOccurredHospital: '', //是否因紧急血运重建入院进行治疗(是：填写紧急血运重建入院日期)
              inHospitalDateUrgent:'', //紧急血运重建入院日期
              isStroke: { //是否发生脑卒中
                stroke:'', //是否发生脑卒中(是：填写卒中类型和脑卒中发生日期)
                strokeType: '', //卒中类型
                strokeDate: '' //脑卒中发生日期
              },
              isStrokeHospital: '', //是否因脑卒中入院进行治疗（是：填写因脑卒中入院日期）
              inHospitalDateStroke: '', //因脑卒中入院日期
              isAngina: '', //是否发生不稳定心绞痛（是：填写不稳定心绞痛发生日期）
              anginaDate: '', //不稳定心绞痛发生日期
              isAnginaHospital: '', //是否因不稳定心绞痛入院进行治疗（是：填写/因不稳定心绞痛入院日期）
              inHospitalDateAngina: '', //因不稳定心绞痛入院日期
              isInfarction: '', //是否发生心肌梗死（是：填写心肌梗死发生日期）
              infarctionDate: '', //心肌梗死发生日期
              isInfarctionHospital:'', //是否因心肌梗死入院进行治疗（是：因心肌梗死入院日期）
              inHospitalDateInfarction:'', //因心肌梗死入院日期
              isAttack: '', //是否短暂性脑缺血发作（是：短暂性脑缺血发作发生日期）
              attackDate: '', //短暂性脑缺血发作发生日期
              isAttackHospital: '', //是否因短暂性脑缺血发作入院进行治疗（是：短暂性脑缺血发作发生日期）
              inHospitalDateAttack: '', //因短暂性脑缺血发作日期
              isStent: '', //是否发生支架内血栓（是：支架内血栓发生日期和血栓分类）
              stentDate: '', //支架内血栓发生日期
              stentType: '', // 血栓分类
              isBlood: '', //是否发生出血(是：填写出血发生日期和BARC类型）
              bloodDate: '', //出血发生日期
              BARCBlood: '',//BARC类型
            }
          }
        },


      },
    }
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle', "比伐芦定在冠心病患者PCI治疗中的应用真实世界研究临床观察表")
  },
  mounted() {
    this.getCaseInfo(this.$route.query.id)
  },
  methods: {
    async getCaseInfo(id){
      const info = await getCaseCHD(id)
      if (info.code===0){
        const member = await memberInfo(info.data.memberId)
        this.doctor_info=member.data
        const form = JSON.parse(JSON.stringify( info.data.template));
        let reg1 = new RegExp("Kg","g");
        let reg2 = new RegExp("cm","g");
        form.demographic.weight=form.demographic.weight.replace(reg1,"");
        form.demographic.height=form.demographic.height.replace(reg2,"");

        //第一次手术血管
        if (form.intervene.bloodVessel.includes('其他')) {
          const [bloodVessel, bloodVessel_other] = form.intervene.bloodVessel.split(':');
          form.intervene.bloodVessel = '';
          form.intervene.bloodVessel_other = bloodVessel_other;
        }
        //第二次手术血管
        if (form.intervene.isSecondSurgery.secondSurgeryBloodVessel.includes('其他')){
          const [bloodVessel, bloodVessel_other] = form.intervene.isSecondSurgery.secondSurgeryBloodVessel.split(':');
          form.intervene.isSecondSurgery.secondSurgeryBloodVessel = '';
          form.intervene.isSecondSurgery.bloodVessel_other = bloodVessel_other;
        }
        //抗凝药物是否使用比伐芦定 “是”加每日剂量
        if (form.drugStatus.doesUse.includes('是')){
          const [value, other] = form.drugStatus.doesUse.split(':');
          form.drugStatus.doesUse = value;
          form.drugStatus.doesUses = other;
        }
        this.case_form=form
      }
    },


    //出生日期计算周岁
    GetAge(strBirthday) {
      let returnAge,
          strBirthdayArr = strBirthday.split("-"),
          birthYear = strBirthdayArr[0],
          birthMonth = strBirthdayArr[1],
          birthDay = strBirthdayArr[2],
          d = new Date(),
          nowYear = d.getFullYear(),
          nowMonth = d.getMonth() + 1,
          nowDay = d.getDate();
      if (nowYear === birthYear) {
        returnAge = 0;//同年 则为0周岁
      } else {
        let ageDiff = nowYear - birthYear; //年之差
        if (ageDiff > 0) {
          if (nowMonth === birthMonth) {
            let dayDiff = nowDay - birthDay;//日之差
            if (dayDiff < 0) {
              returnAge = ageDiff - 1;
            } else {
              returnAge = ageDiff;
            }
          } else {
            let monthDiff = nowMonth - birthMonth;//月之差
            if (monthDiff < 0) {
              returnAge = ageDiff - 1;
            } else {
              returnAge = ageDiff;
            }
          }
        } else {
          returnAge = -1;//返回-1 表示出生日期输入错误 晚于今天
        }
      }
      return returnAge;//返回周岁年龄//
    }
  }
}
</script>

<style lang="scss" scoped>
@media print {
  .table_header:before {
    content: '*';
    color: red;
  }
}
.CHD_case {
  margin: -15px -15px;
  width: 60%;
  min-height: 100%;
  margin: 0 auto;

  .table_case {
    margin-top: 20px;
    margin-bottom: 2%;

    .table_header {
      font-family: 黑体;
      font-weight: bold;
    }
    .table_header2 {
      font-family: 黑体;
      font-weight: bold;
    }
    .table_header:before {
      content: '*';
      color: red;
    }
  }
}
</style>
